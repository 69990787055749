<template>
  <div class="goal-step abs-full-block">
    <div class="sign-in__flex">
      <div class="w-100">
        <p class="text-muted mb-0">2 из 5</p>
        <p class="goal-step__title">Ваша цель?</p>
          <template v-if="purposes">
            <label class="goal-step__selection" v-for="(item, index) in purposes" :key="index" :for="'selection'+item.id">
              <input type="radio" :id="'selection'+item.id" name="goal" :value="item.id" v-model="purpose_id">
              <div class="goal-step__selection__block">
                <div>
                  <p class="goal-step__selection__title">{{ item.name }}</p>
                  <p class="goal-step__selection__body">{{ item.description }}</p>
                </div>
                <img :src="item.image" :alt="item.name">
              </div>
            </label>
          </template>
        <Preloader v-else></Preloader>


          <RegistrationMenu/>
          <div class="w-100">
            <button @click="collectData" class="base-btn base-btn--violet" >Продолжить</button>
          </div>
      </div>

    </div>
  </div>
</template>
<script>
import RegistrationMenu from '../../partials/RegistrationMenu'
import Purposes from "@/api/Purposes";
import Preloader from '../../static/Preloader'
export default {
  components: {
    RegistrationMenu,
    Preloader
  },
  data(){
    return {
      purposes: null,
      errors: null,
      purpose_id: null,
    }
  },

  mounted() {
    this.getPurposes()
  },
  methods: {
    getPurposes() {
      Purposes.list(data => this.purposes = data, errors => this.errors = errors)
    },
    collectData() {
      if (this.purpose_id !== null  ) {
        let params = {
         purpose_id: this.purpose_id
        }
        this.$store.dispatch('COLLECT_USER_DATA', params)
            .then(() => {
              this.$router.push({ name: 'registration-step-3' })
            })
      } else {
        this.$store.dispatch('SHOW_ALERT', ['Выберите вашу цель'])
      }
    },
  }
}
</script>