<template>
  <div class="w-100">
    <div v-for="(dish, index_dish) in data" :key="index_dish+'dish_ing'" class="shadow-sm p-2">
      <div class="block-small mb-3">
        <img :src="dish.image" alt="" class="block-small__cover">
        <div class="block-small__body">
          <div>
            <span
                class="mr-1"
                v-for="(item, index) in dish.lights"
                :key="'light'+index"
            >
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="5" :fill="item.colorHex"/>
            </svg>
          </span>
          </div>
          <p class="block-small__title mb-1 mt-2">{{ dish.name }}</p>

          <div class="d-flex">
            <p class="mr-3 block-large__info"><img src="../../../assets/images/food.svg" alt=""> {{ dish.kilocalories }} кал</p>
            <p class="block-large__info"><img src="../../../assets/images/weight.svg" alt=""> {{ dish.weight }} грамм</p>
          </div>
        </div>
      </div>
      <label
          class="base-checkbox-full"
          v-for="(item, index) in dish.ingredients "
          :key="index+'shop-list'"
      >
        <div class="shopping__item">
          <img :src="item.image" alt="">
          <div>
            <p class="shopping__item__title">{{ item.name }}</p>
            <p class="shopping__item__description">{{ item.avg_serving_size }}</p>
          </div>
        </div>
        <input type="checkbox" :checked="item.status" value=false>
        <span class="checkmark-full"></span>
      </label>
    </div>
  </div>
</template>
<script>
export default{
  props: ['data']
}
</script>