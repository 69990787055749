import {HTTP} from '@/http'

class Restrictions {

    static list(then, catcher, params = null) {
        return HTTP.get('/restrictions', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

}
export default Restrictions
