<template>
  <div class="goal-step abs-full-block">
    <div class="sign-in__flex">
      <div class="w-100">
        <p class="text-muted mb-0">4 из 5</p>
        <p class="goal-step__title">Хочу меню без...</p>
      </div>
      <div class="w-100">
        <template v-if="limitations">
          <label class="base-checkbox"
                 v-for="(item, index) in limitations"
                 :key="'limitatoins'+index"
                 :for="'limitation'+item.id"
          >
            {{ item.name }}
            <input type="checkbox" :id="'limitation'+item.id"  :value="item.id" v-model="limitation_list">
            <span class="checkmark"></span>
          </label>
        </template>
        <Preloader v-else></Preloader>
      </div>

      <div class="w-100">
        <RegistrationMenu/>
        <button @click="collectData" class="base-btn base-btn--violet">Продолжить</button>
      </div>

    </div>
  </div>
</template>
<script>
import RegistrationMenu from '../../partials/RegistrationMenu'
import Preferences from "@/api/Preferences"
import Preloader from '../../static/Preloader'
export default {
  components: {
    RegistrationMenu,
    Preloader
  },
  data() {
    return {
      limitation_list: [],
      limitations: null,
      errors: null
    }
  },

  mounted() {
    this.getPreferences()
  },
  methods: {
    getPreferences(){
      Preferences.list(data => this.limitations = data, errors => this.errors = errors)
    },
    collectData() {
      let params = {
        limitation_ids: this.limitation_list
      }
      this.$store.dispatch('COLLECT_USER_DATA', params)
          .then(() => {
            this.$router.push({ name: 'registration-step-6' })
          })
    },
  }
}
</script>