<template>
  <div class="gradient-swiper">
    <Slick
        ref="slick"
        :options="swiper_options" >
      <router-link class="gradient-swiper__block"
                   v-for="(item, index) in data"
                   :key="index"
                   :to="{name: 'diet-plan', params: {id: item.id}}"
      >
        <div class="gradient-swiper__block__out">
          <div :class="['abs-full-block', {'gradient-bg-blue': index % 2 === 0, 'gradient-bg-pink': index % 2 !== 0 }]">
            <p class="gradient-swiper__title"> {{item.name}} </p>
<!--            <div class="base-flex">-->
<!--              <p><img src="../../../assets/images/date.svg" alt=""> {{item.date}} </p>-->
<!--              <p><img src="../../../assets/images/time.svg" alt=""> {{item.time}} </p>-->
<!--            </div>-->
          </div>
          <img class="gradient-swiper__img" :src="item.image" alt="">
        </div>
      </router-link>

    </Slick>
  </div>
</template>
<script>
import Slick from 'vue-slick';
export default {
  components: {
    Slick,
  },
  data() {
    return {
      swiper_options: {
        slidesToShow: 2,
        arrows: false,
        dots: false
      },
    }
  },
  props: ['data']
}
</script>