<template>
  <div class="atlas">
    <router-link :to="{name: 'plan'}" class="atlas__title pt-4 pb-3"> <img src="../../../assets/images/arrow-left-to-back.svg" alt="">Молекулярный атлас</router-link>

    <ul class="atlas__tab">
      <li @click="getVitamins" :class="[ vitamins ? 'active' : '']">Витамины</li>
      <li @click="getMinerals" :class="[ minerals ? 'active' : '']">Минералы</li>
      <li @click="getMacronutrients" :class="[ macronutrients ? 'active' : '']">Макронутриенты</li>
    </ul>
<!--    <div v-if="data">-->
<!--      <div v-if="data.atlas">-->
<!--        <pre>{{data.atlas.vitamins}}</pre>-->
<!--      </div>-->
<!--    </div>-->

    <template v-if="vitamins">
      <div class="atlas__info mt-3">
        <div class="atlas__info__border">
          <p class="atlas__info__title"> <img src="../../../assets/images/ant-design_info-circle-filled.svg" alt=""> Важно знать!</p>
          <div class="atlas__info__flex">
            <p><img src="../../../assets/images/day_for.svg" alt=""> Съедено за день</p>
            <p><img src="../../../assets/images/day_normal.svg" alt=""> Дневная норма</p>
            <p><img src="../../../assets/images/critical.svg" alt=""> Критический уровень</p>
          </div>
        </div>
      </div>

      <template v-if="data">
        <template v-if="data.atlas">
          <div class="atlas__board" v-for="(item, index) in data.atlas.vitamins" :key="index + 'vitamin'">
            <div class="atlas__board__top">
              <router-link :to="{name: 'atlas-single', params: {id: 1}}" class="atlas__board__title">{{ item.name }}</router-link>
              <div class="btn-group">
                <button type="button" class="atlas__board__menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img src="../../../assets/images/vitamins-block-menu.svg" alt="">
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <button class="dropdown-item" type="button"><router-link :to="{name: 'atlas-single', params: {id: item.id}}" >{{ item.name }}</router-link></button>
                  <!--              <button class="dropdown-item" type="button">Another action</button>-->
                  <!--              <button class="dropdown-item" type="button">Something else here</button>-->
                </div>
              </div>
            </div>
            <div class="atlas__board__body">
              <p>В меню на день</p>
              <p class="dark text-bold" v-if="item.generated_intake">{{item.generated_intake}} {{ item.unit_name }}</p>
              <p class="grey">{{  Math.round(item.generated_intake * 100 / item.daily_intake) }}%</p>
            </div>
            <template v-if="item.daily_intake_max">
              <!--          если есть значение максимума за день -->

              <template v-if="item.daily_intake_max > item.current_intake">
                <template v-if="item.current_intake < item.daily_intake">
                  <!--          если сьедано за день менше чем рекоменуемые -->
                  <!--          сьедано за день  = проценты отнасително критического -->
                  <!--          рекомендуемые  = проценты критический - рекомендованный -->
                  <!--          кретический  = 100 - рекомондованный -->
                  <!--            <pre>eaten {{ (parseInt(item.current_intake)*100/parseInt(item.daily_intake_max))}}</pre>-->
                  <!--            <pre>recommend {{(100 - (100 - (parseInt(item.daily_intake)*100/parseInt(item.daily_intake_max))) - (parseInt(item.current_intake)*100/parseInt(item.daily_intake_max)))}}</pre>-->
                  <!--            <pre>critical {{(100 - (parseInt(item.daily_intake)*100/parseInt(item.daily_intake_max)))}}</pre>-->
                  <div class="progress">
                    <!--  Съедено за день -->
                    <div class="progress-bar bg-success eaten_in_a_day"
                         :style="{ 'width' : (parseInt(item.current_intake)*100/parseInt(item.daily_intake*2)) +'%' }"
                    >
                      <img src="../../../assets/images/day_for.svg" alt="">
                      <p><span>{{ Math.round(item.current_intake*10) /10}}</span></p>
                    </div>
                    <!-- рекомендованный -->
                    <div class="progress-bar bg-light daily_rate"
                        :style="{ 'width' : (100 - (100 - (parseInt(item.daily_intake)*100/parseInt(item.daily_intake*2))) - (parseInt(item.current_intake)*100/parseInt(item.daily_intake*2))) +'%' }"
                    >
                      <img src="../../../assets/images/day_normal.svg" alt="">
                      <p><span>{{ Math.round(item.daily_intake*10) /10 }}</span></p>
                    </div>
                    <!--  Критический уровень -->
<!--                    <div class="progress-bar bg-light critical"-->
<!--                         :style="{ 'width' : (100 - (parseInt(item.daily_intake)*100/parseInt(item.daily_intake_max))) +'%' }"-->
<!--                    >-->
<!--                      <img src="../../../assets/images/critical.svg" alt="">-->
<!--                      <p><span>{{ Math.round(item.daily_intake_max) }}</span></p>-->
<!--                    </div>-->
                    <div class="progress-bar bg-light critical"
                         :style="{ 'width' : ((100 - (100 - (parseInt(item.daily_intake)*100/parseInt(item.daily_intake*2))) - (parseInt(item.current_intake)*100/parseInt(item.daily_intake*2)))*2) +'%' }"
                    >
                      <img src="../../../assets/images/critical.svg" alt="">
                      <p><span>{{ Math.round(item.daily_intake_max*10) /10 }}</span></p>
                    </div>
                  </div>
                </template>
                <template v-if="item.current_intake > item.daily_intake">
                  <!--          если сьедано за день больше чем рекоменуемые -->
                  <!--          рекомендуемые  = проценты отнасително критического -->
                  <!--          сьедано за день  =  (сьедано за день - рекомендованный) проценты отнасително критического-->
                  <!--          критический  = 100 - седано за день -->
                  <!--            <pre>recommend {{ (parseInt(item.daily_intake)*100/parseInt(item.daily_intake_max)) }}</pre>-->
                  <!--            <pre>eaten {{ ((parseInt(item.current_intake - item.daily_intake)*100/parseInt(item.daily_intake_max))) }}</pre>-->
                  <!--            <pre>critical {{(100 - (parseInt(item.current_intake)*100/parseInt(item.daily_intake_max)))}}</pre>-->

                  <div class="progress">
                    <!-- рекомендованный -->
                    <div class="progress-bar bg-success daily_rate"
                         :style="{ 'width' : (parseInt(item.daily_intake)*100/parseInt(item.daily_intake*2)) +'%' }"
                    >
                      <img src="../../../assets/images/day_normal.svg" alt="">
                      <p><span>{{ Math.round(item.daily_intake*10) /10 }}</span></p>
                    </div>
                    <!--  Съедено за день -->
                    <div class="progress-bar bg-warning eaten_in_a_day"
                         :style="{ 'width' : ((parseInt(item.current_intake - item.daily_intake)*100/parseInt(item.daily_intake_max))) +'%' ,
                          'margin-left' : -(50 - ((parseInt(item.current_intake - item.daily_intake)*100/parseInt(item.daily_intake_max)))) +'%'
                       }"
                    >
                      <img src="../../../assets/images/day_for.svg" alt="">
                      <p><span>{{ Math.round(item.current_intake*10) /10 }}</span></p>
                    </div>
                    <!--  Критический уровень -->
                    <div class="progress-bar bg-light critical"
                         :style="{ 'width' : ((100 - (100 - (parseInt(item.daily_intake)*100/parseInt(item.daily_intake*2))) - (parseInt(item.current_intake)*100/parseInt(item.daily_intake*2)))*2) +'%' }"
                    >
                      <img src="../../../assets/images/critical.svg" alt="">
                      <p><span>{{ Math.round(item.daily_intake_max*10) /10 }}</span></p>
                    </div>
                  </div>
                </template>
              </template>
              <template v-else>
                <!--          если сьедано за день больше чем критического тогда точно больше чем рекомендованного -->

                <!--          рекомендуемые  = проценты отнасително сьедано за день (максимума) -->
                <!--          сьедано за день  =  (сьедано за день - рекомендованный) проценты отнасително критического-->
                <!--          критический  = 100 - седано за день -->

                <!--            <pre>recommend {{ (parseInt(item.daily_intake)*100/parseInt(item.current_intake)) }}</pre>-->
                <!--            <pre>critical {{  ((parseInt(item.daily_intake_max - item.daily_intake) * 100) / item.current_intake) }} </pre>-->
                <!--            <pre>eaten {{ parseInt(100 - (parseInt(item.daily_intake_max)*100/parseInt(item.current_intake))) }} </pre>-->

                <div class="progress">
                  <!-- рекомендованный -->
                  <div class="progress-bar bg-success daily_rate"
                       :style="{ 'width' : (item.daily_intake*100/item.current_intake) +'%' }"
                  >
                    <img src="../../../assets/images/day_normal.svg" alt="">
                    <p><span>{{ Math.round(item.daily_intake*10) /10 }}</span></p>
                  </div>

                  <!--  Критический уровень -->
                  <div class="progress-bar bg-warning critical"
                       :style="{ 'width' : (((item.daily_intake_max - item.daily_intake) * 100) / item.current_intake) +'%' }"
                  >
                    <img src="../../../assets/images/critical.svg" alt="">
                    <p><span>{{ Math.round(item.daily_intake_max*10) /10 }}</span></p>
                  </div>

                  <!--  Съедено за день -->
                  <div class="progress-bar bg-danger eaten_in_a_day"
                       :style="{ 'width' :  (100 - (item.daily_intake_max*100/item.current_intake)) +'%' }"
                  >
                    <img src="../../../assets/images/day_for.svg" alt="">
                    <p><span>{{ Math.round(item.current_intake*10) /10 }}</span></p>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <!--          если нет значение максимума за день -->
              <template v-if="item.current_intake < item.daily_intake">
                <!--            если сьедано за день менше чем рекоменуемые -->

                <!--            сьедано за день  = проценты отнасително рекоменуемые -->
                <!--            рекомендуемые  = проценты 100 - сьедано за день -->

                <!--            <pre>eaten {{ (parseInt(item.current_intake)*100/parseInt(item.daily_intake))}}</pre>-->
                <!--            <pre>recommend {{ (100 - (parseInt(item.current_intake)*100/parseInt(item.daily_intake))) }}</pre>-->
                <div class="progress">
                  <!--  Съедено за день -->
                  <div class="progress-bar bg-success eaten_in_a_day"
                       :style="{ 'width' : (item.current_intake*100/item.daily_intake) +'%' }"
                  >
                    <img src="../../../assets/images/day_for.svg" alt="">
                    <p><span>{{ Math.round(item.current_intake*10) /10 }}</span></p>
                  </div>
                  <!--               рекомендованный-->
                  <div class="progress-bar bg-light daily_rate"
                       :style="{ 'width' :  100 - (item.current_intake*100/item.daily_intake) +'%' }"
                  >
                    <img src="../../../assets/images/day_normal.svg" alt="">
                    <p><span>{{ Math.round(item.daily_intake*10) /10 }}</span></p>
                  </div>
                </div>
              </template>
              <template v-if="item.current_intake > item.daily_intake">
                <!--          если сьедано за день больше чем рекоменуемые -->

                <!--          рекомендуемые  = проценты отнасително сьедано за день -->
                <!--          сьедано за день  =  (сьедано за день - рекомендованный) проценты отнасително критического-->

                <!--          <pre>recommend {{ (parseInt(item.daily_intake)*100/parseInt(item.current_intake)) }}</pre>-->
                <!--          <pre>eaten {{ 100 - (parseInt(item.daily_intake)*100/parseInt(item.current_intake)) }}</pre>-->
                <div class="progress">
                  <!-- рекомендованный -->
                  <div class="progress-bar bg-success daily_rate"
                       :style="{ 'width' : (item.daily_intake*100/item.current_intake) +'%' }"
                  >
                    <img src="../../../assets/images/day_normal.svg" alt="">
                    <p><span>{{ Math.round(item.daily_intake*10) /10 }}</span></p>
                  </div>
                  <!--  Съедено за день -->
                  <div class="progress-bar bg-warning eaten_in_a_day"
                       :style="{ 'width' :   100 - (item.daily_intake*100/item.current_intake) +'%' }"
                  >
                    <img src="../../../assets/images/day_for.svg" alt="">
                    <p><span>{{ Math.round(item.current_intake*10) /10}}</span></p>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </template>
      </template>
      <Preloader v-else></Preloader>
    </template>
    <template v-if="minerals">
      <div class="atlas__info mt-3">
        <div class="atlas__info__border">
          <p class="atlas__info__title"> <img src="../../../assets/images/ant-design_info-circle-filled.svg" alt=""> Важно знать!</p>
          <div class="atlas__info__flex">
            <p><img src="../../../assets/images/day_for.svg" alt=""> Съедено за день</p>
            <p><img src="../../../assets/images/day_normal.svg" alt=""> Дневная норма</p>
            <p><img src="../../../assets/images/critical.svg" alt=""> Критический уровень</p>
          </div>
        </div>
      </div>
      <template v-if="data">
        <template v-if="data.atlas">
          <div class="atlas__board" v-for="(item, index) in data.atlas.minerals" :key="index + 'mineral'">
            <div class="atlas__board__top">
              <router-link :to="{name: 'atlas-single', params: {id: 1}}" class="atlas__board__title">{{ item.name }}</router-link>
              <div class="btn-group">
                <button type="button" class="atlas__board__menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img src="../../../assets/images/vitamins-block-menu.svg" alt="">
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <button class="dropdown-item" type="button"><router-link :to="{name: 'atlas-single', params: {id: item.id}}" >{{ item.name }}</router-link></button>
                  <!--              <button class="dropdown-item" type="button">Another action</button>-->
                  <!--              <button class="dropdown-item" type="button">Something else here</button>-->
                </div>
              </div>
            </div>
            <div class="atlas__board__body">
              <p>В меню на день</p>
              <p class="dark text-bold" v-if="item.generated_intake">{{item.generated_intake}} {{ item.unit_name }}</p>
              <p class="grey">{{  Math.round(item.generated_intake * 100 / item.daily_intake) }}%</p>
            </div>
            <template v-if="item.daily_intake_max">
              <!--          если есть значение максимума за день -->

              <template v-if="item.daily_intake_max > item.current_intake">
                <template v-if="item.current_intake < item.daily_intake">
                  <!--          если сьедано за день менше чем рекоменуемые -->
                  <!--          сьедано за день  = проценты отнасително критического -->
                  <!--          рекомендуемые  = проценты критический - рекомендованный -->
                  <!--          кретический  = 100 - рекомондованный -->
                  <!--            <pre>eaten {{ (parseInt(item.current_intake)*100/parseInt(item.daily_intake_max))}}</pre>-->
                  <!--            <pre>recommend {{(100 - (100 - (parseInt(item.daily_intake)*100/parseInt(item.daily_intake_max))) - (parseInt(item.current_intake)*100/parseInt(item.daily_intake_max)))}}</pre>-->
                  <!--            <pre>critical {{(100 - (parseInt(item.daily_intake)*100/parseInt(item.daily_intake_max)))}}</pre>-->
                  <div class="progress">
                    <!--  Съедено за день -->
                    <div class="progress-bar bg-success eaten_in_a_day"
                         :style="{ 'width' : ((item.current_intake*100/item.daily_intake_max)/2+(item.current_intake*100/item.daily_intake*2)/2) +'%' }"
                    >
                      <img src="../../../assets/images/day_for.svg" alt="">
                      <p><span>{{ Math.round(item.current_intake*10) /10 }}</span></p>
                    </div>
                    <!-- рекомендованный -->
                    <div class="progress-bar bg-light daily_rate"
                         :style="{ 'width' : (100 - (100 - (parseInt(item.daily_intake)*100/parseInt(item.daily_intake*2))) - (parseInt(item.current_intake)*100/parseInt(item.daily_intake*2))) +'%' }"
                    >
                      <img src="../../../assets/images/day_normal.svg" alt="">
                      <p><span>{{ Math.round(item.daily_intake*10) /10 }}</span></p>
                    </div>
                    <!--  Критический уровень -->
                    <div class="progress-bar bg-light critical"
                         :style="{ 'width' : ((100 - (100 - (parseInt(item.daily_intake)*100/parseInt(item.daily_intake*2))) - (parseInt(item.current_intake)*100/parseInt(item.daily_intake*2)))*2) +'%' }"
                    >
                      <img src="../../../assets/images/critical.svg" alt="">
                      <p><span>{{ Math.round(item.daily_intake_max*10) /10 }}</span></p>
                    </div>
                  </div>
                </template>
                <template v-if="item.current_intake > item.daily_intake">
                  <!--          если сьедано за день больше чем рекоменуемые -->
                  <!--          рекомендуемые  = проценты отнасително критического -->
                  <!--          сьедано за день  =  (сьедано за день - рекомендованный) проценты отнасително критического-->
                  <!--          критический  = 100 - седано за день -->
                  <!--            <pre>recommend {{ (parseInt(item.daily_intake)*100/parseInt(item.daily_intake_max)) }}</pre>-->
                  <!--            <pre>eaten {{ ((parseInt(item.current_intake - item.daily_intake)*100/parseInt(item.daily_intake_max))) }}</pre>-->
                  <!--            <pre>critical {{(100 - (parseInt(item.current_intake)*100/parseInt(item.daily_intake_max)))}}</pre>-->

                  <div class="progress">
                    <!-- рекомендованный -->
                    <div class="progress-bar bg-success daily_rate"
                         :style="{ 'width' : (parseInt(item.daily_intake)*100/parseInt(item.daily_intake*2)) +'%' }"
                    >
                      <img src="../../../assets/images/day_normal.svg" alt="">
                      <p><span>{{ Math.round(item.daily_intake*10) /10 }}</span></p>
                    </div>
                    <!--  Съедено за день -->
                    <div class="progress-bar bg-warning eaten_in_a_day"
                         :style="{ 'width' : ((parseInt(item.current_intake - item.daily_intake)*100/parseInt(item.daily_intake_max))) +'%' ,
                          'margin-left' : -(50 - ((parseInt(item.current_intake - item.daily_intake)*100/parseInt(item.daily_intake_max)))) +'%'
                       }"
                    >
                      <img src="../../../assets/images/day_for.svg" alt="">
                      <p><span>{{ Math.round(item.current_intake*10) /10}}</span></p>
                    </div>
                    <!--  Критический уровень -->
                    <div class="progress-bar bg-light critical"
                         :style="{ 'width' : ((100 - (100 - (parseInt(item.daily_intake)*100/parseInt(item.daily_intake*2))) - (parseInt(item.current_intake)*100/parseInt(item.daily_intake*2)))*2) +'%' }"
                    >
                      <img src="../../../assets/images/critical.svg" alt="">
                      <p><span>{{ Math.round(item.daily_intake_max*10) /10 }}</span></p>
                    </div>
                  </div>
                </template>
              </template>
              <template v-else>
                <!--          если сьедано за день больше чем критического тогда точно больше чем рекомендованного -->

                <!--          рекомендуемые  = проценты отнасително сьедано за день (максимума) -->
                <!--          сьедано за день  =  (сьедано за день - рекомендованный) проценты отнасително критического-->
                <!--          критический  = 100 - седано за день -->

                <!--            <pre>recommend {{ (parseInt(item.daily_intake)*100/parseInt(item.current_intake)) }}</pre>-->
                <!--            <pre>critical {{  ((parseInt(item.daily_intake_max - item.daily_intake) * 100) / item.current_intake) }} </pre>-->
                <!--            <pre>eaten {{ parseInt(100 - (parseInt(item.daily_intake_max)*100/parseInt(item.current_intake))) }} </pre>-->

                <div class="progress">
                  <!-- рекомендованный -->
                  <div class="progress-bar bg-success daily_rate"
                       :style="{ 'width' : (item.daily_intake*100/item.current_intake) +'%' }"
                  >
                    <img src="../../../assets/images/day_normal.svg" alt="">
                    <p><span>{{ Math.round(item.daily_intake*10) /10 }}</span></p>
                  </div>

                  <!--  Критический уровень -->
                  <div class="progress-bar bg-warning critical"
                       :style="{ 'width' : (((item.daily_intake_max - item.daily_intake) * 100) / item.current_intake) +'%' }"
                  >
                    <img src="../../../assets/images/critical.svg" alt="">
                    <p><span>{{ Math.round(item.daily_intake_max*10) /10}}</span></p>
                  </div>

                  <!--  Съедено за день -->
                  <div class="progress-bar bg-danger eaten_in_a_day"
                       :style="{ 'width' :  (100 - (item.daily_intake_max*100/item.current_intake)) +'%' }"
                  >
                    <img src="../../../assets/images/day_for.svg" alt="">
                    <p><span>{{ Math.round(item.current_intake*10) /10}}</span></p>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <!--          если нет значение максимума за день -->
              <template v-if="item.current_intake < item.daily_intake">
                <!--            если сьедано за день менше чем рекоменуемые -->

                <!--            сьедано за день  = проценты отнасително рекоменуемые -->
                <!--            рекомендуемые  = проценты 100 - сьедано за день -->

                <!--            <pre>eaten {{ (parseInt(item.current_intake)*100/parseInt(item.daily_intake))}}</pre>-->
                <!--            <pre>recommend {{ (100 - (parseInt(item.current_intake)*100/parseInt(item.daily_intake))) }}</pre>-->
                <div class="progress">
                  <!--  Съедено за день -->
                  <div class="progress-bar bg-success eaten_in_a_day"
                       :style="{ 'width' : (item.current_intake*100/item.daily_intake) +'%' }"
                  >
                    <img src="../../../assets/images/day_for.svg" alt="">
                    <p><span>{{ Math.round(item.current_intake*10) /10 }}</span></p>
                  </div>
                  <!--               рекомендованный-->
                  <div class="progress-bar bg-light daily_rate"
                       :style="{ 'width' :  100 - (item.current_intake*100/item.daily_intake) +'%' }"
                  >
                    <img src="../../../assets/images/day_normal.svg" alt="">
                    <p><span>{{ Math.round(item.daily_intake*10) /10 }}</span></p>
                  </div>
                </div>
              </template>
              <template v-if="item.current_intake > item.daily_intake">
                <!--          если сьедано за день больше чем рекоменуемые -->

                <!--          рекомендуемые  = проценты отнасително сьедано за день -->
                <!--          сьедано за день  =  (сьедано за день - рекомендованный) проценты отнасително критического-->

                <!--          <pre>recommend {{ (parseInt(item.daily_intake)*100/parseInt(item.current_intake)) }}</pre>-->
                <!--          <pre>eaten {{ 100 - (parseInt(item.daily_intake)*100/parseInt(item.current_intake)) }}</pre>-->
                <div class="progress">
                  <!-- рекомендованный -->
                  <div class="progress-bar bg-success daily_rate"
                       :style="{ 'width' : (item.daily_intake*100/item.current_intake) +'%' }"
                  >
                    <img src="../../../assets/images/day_normal.svg" alt="">
                    <p><span>{{ Math.round(item.daily_intake*10) /10 }}</span></p>
                  </div>
                  <!--  Съедено за день -->
                  <div class="progress-bar bg-warning eaten_in_a_day"
                       :style="{ 'width' :   100 - (item.daily_intake*100/item.current_intake) +'%' }"
                  >
                    <img src="../../../assets/images/day_for.svg" alt="">
                    <p><span>{{ Math.round(item.current_intake*10) /10 }}</span></p>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </template>
      </template>
      <Preloader v-else></Preloader>
    </template>
    <template v-if="macronutrients">
      <div class="atlas__info mt-3">
        <div class="atlas__info__border">
          <p class="atlas__info__title"> <img src="../../../assets/images/ant-design_info-circle-filled.svg" alt=""> Важно знать!</p>
          <div class="atlas__info__flex">
            <p><img src="../../../assets/images/day_for.svg" alt=""> Съедено за день</p>
            <p><img src="../../../assets/images/day_normal.svg" alt=""> Дневная норма</p>
            <p><img src="../../../assets/images/critical.svg" alt=""> Критический уровень</p>
          </div>
        </div>
      </div>
      <template v-if="data">
        <template v-if="data.atlas">
          <div class="atlas__board" v-for="(item, index) in data.atlas.macronutriens" :key="index + 'macro'">
            <div class="atlas__board__top">
              <router-link :to="{name: 'atlas-single', params: {id: 1}}" class="atlas__board__title">{{ item.name }}</router-link>
              <div class="btn-group">
                <button type="button" class="atlas__board__menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img src="../../../assets/images/vitamins-block-menu.svg" alt="">
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <button class="dropdown-item" type="button"><router-link :to="{name: 'atlas-single', params: {id: item.id}}" >{{ item.name }}</router-link></button>
                  <!--              <button class="dropdown-item" type="button">Another action</button>-->
                  <!--              <button class="dropdown-item" type="button">Something else here</button>-->
                </div>
              </div>
            </div>
            <div class="atlas__board__body">
              <p>В меню на день</p>
              <p class="dark text-bold" v-if="item.generated_intake">{{item.generated_intake}} {{ item.unit_name }}</p>
              <p class="grey">{{  Math.round(item.generated_intake * 100 / item.daily_intake) }}%</p>
            </div>
            <template v-if="item.daily_intake_max">
              <!--          если есть значение максимума за день -->

              <template v-if="item.daily_intake_max > item.current_intake">
                <template v-if="item.current_intake < item.daily_intake">
                  <!--          если сьедано за день менше чем рекоменуемые -->
                  <!--          сьедано за день  = проценты отнасително критического -->
                  <!--          рекомендуемые  = проценты критический - рекомендованный -->
                  <!--          кретический  = 100 - рекомондованный -->
                  <!--            <pre>eaten {{ (parseInt(item.current_intake)*100/parseInt(item.daily_intake_max))}}</pre>-->
                  <!--            <pre>recommend {{(100 - (100 - (parseInt(item.daily_intake)*100/parseInt(item.daily_intake_max))) - (parseInt(item.current_intake)*100/parseInt(item.daily_intake_max)))}}</pre>-->
                  <!--            <pre>critical {{(100 - (parseInt(item.daily_intake)*100/parseInt(item.daily_intake_max)))}}</pre>-->
                  <div class="progress">
                    <!--  Съедено за день -->
                    <div class="progress-bar bg-success eaten_in_a_day"
                         :style="{ 'width' : (item.current_intake*100/item.daily_intake_max) +'%' }"
                    >
                      <img src="../../../assets/images/day_for.svg" alt="">
                      <p><span>{{ Math.round(item.current_intake*10) /10 }}</span></p>
                    </div>
                    <!-- рекомендованный -->
                    <div class="progress-bar bg-light daily_rate"
                         :style="{ 'width' : (100 - (100 - (item.daily_intake*100/item.daily_intake_max)) - (item.current_intake*100/item.daily_intake_max)) +'%' }"
                    >
                      <img src="../../../assets/images/day_normal.svg" alt="">
                      <p><span>{{ Math.round(item.daily_intake*10) /10 }}</span></p>
                    </div>
                    <!--  Критический уровень -->
                    <div class="progress-bar bg-light critical"
                         :style="{ 'width' : (100 - (item.daily_intake*100/item.daily_intake_max)) +'%' }"
                    >
                      <img src="../../../assets/images/critical.svg" alt="">
                      <p><span>{{ Math.round(item.daily_intake_max*10) /10 }}</span></p>
                    </div>
                  </div>
                </template>
                <template v-if="item.current_intake > item.daily_intake">
                  <!--          если сьедано за день больше чем рекоменуемые -->
                  <!--          рекомендуемые  = проценты отнасително критического -->
                  <!--          сьедано за день  =  (сьедано за день - рекомендованный) проценты отнасително критического-->
                  <!--          критический  = 100 - седано за день -->
                  <!--            <pre>recommend {{ (parseInt(item.daily_intake)*100/parseInt(item.daily_intake_max)) }}</pre>-->
                  <!--            <pre>eaten {{ ((parseInt(item.current_intake - item.daily_intake)*100/parseInt(item.daily_intake_max))) }}</pre>-->
                  <!--            <pre>critical {{(100 - (parseInt(item.current_intake)*100/parseInt(item.daily_intake_max)))}}</pre>-->

                  <div class="progress">
                    <!-- рекомендованный -->
                    <div class="progress-bar bg-success daily_rate"
                         :style="{ 'width' : (item.daily_intake*100/item.daily_intake_max) +'%' }"
                    >
                      <img src="../../../assets/images/day_normal.svg" alt="">
                      <p><span>{{ Math.round(item.daily_intake*10) /10 }}</span></p>
                    </div>
                    <!--  Съедено за день -->
                    <div class="progress-bar bg-warning eaten_in_a_day"
                         :style="{ 'width' : (((item.current_intake - item.daily_intake)*100/item.daily_intake_max)) +'%' }"
                    >
                      <img src="../../../assets/images/day_for.svg" alt="">
                      <p><span>{{ Math.round(item.current_intake*10) /10 }}</span></p>
                    </div>
                    <!--  Критический уровень -->
                    <div class="progress-bar bg-light critical"
                         :style="{ 'width' : (100 - (item.current_intake*100/item.daily_intake_max)) +'%' }"
                    >
                      <img src="../../../assets/images/critical.svg" alt="">
                      <p><span>{{ Math.round(item.daily_intake_max*10) /10 }}</span></p>
                    </div>
                  </div>
                </template>
              </template>
              <template v-else>
                <!--          если сьедано за день больше чем критического тогда точно больше чем рекомендованного -->

                <!--          рекомендуемые  = проценты отнасително сьедано за день (максимума) -->
                <!--          сьедано за день  =  (сьедано за день - рекомендованный) проценты отнасително критического-->
                <!--          критический  = 100 - седано за день -->

                <!--            <pre>recommend {{ (parseInt(item.daily_intake)*100/parseInt(item.current_intake)) }}</pre>-->
                <!--            <pre>critical {{  ((parseInt(item.daily_intake_max - item.daily_intake) * 100) / item.current_intake) }} </pre>-->
                <!--            <pre>eaten {{ parseInt(100 - (parseInt(item.daily_intake_max)*100/parseInt(item.current_intake))) }} </pre>-->

                <div class="progress">
                  <!-- рекомендованный -->
                  <div class="progress-bar bg-success daily_rate"
                       :style="{ 'width' : (item.daily_intake*100/item.current_intake) +'%' }"
                  >
                    <img src="../../../assets/images/day_normal.svg" alt="">
                    <p><span>{{ Math.round(item.daily_intake*10) /10 }}</span></p>
                  </div>

                  <!--  Критический уровень -->
                  <div class="progress-bar bg-warning critical"
                       :style="{ 'width' : (((item.daily_intake_max - item.daily_intake) * 100) / item.current_intake) +'%' }"
                  >
                    <img src="../../../assets/images/critical.svg" alt="">
                    <p><span>{{ Math.round(item.daily_intake_max*10) /10 }}</span></p>
                  </div>

                  <!--  Съедено за день -->
                  <div class="progress-bar bg-danger eaten_in_a_day"
                       :style="{ 'width' :  parseInt(100 - (item.daily_intake_max*100/item.current_intake)) +'%' }"
                  >
                    <img src="../../../assets/images/day_for.svg" alt="">
                    <p><span>{{ Math.round(item.current_intake*10) /10 }}</span></p>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <!--          если нет значение максимума за день -->
              <template v-if="item.current_intake < item.daily_intake">
                <!--            если сьедано за день менше чем рекоменуемые -->

                <!--            сьедано за день  = проценты отнасително рекоменуемые -->
                <!--            рекомендуемые  = проценты 100 - сьедано за день -->

                <!--            <pre>eaten {{ (parseInt(item.current_intake)*100/parseInt(item.daily_intake))}}</pre>-->
                <!--            <pre>recommend {{ (100 - (parseInt(item.current_intake)*100/parseInt(item.daily_intake))) }}</pre>-->
                <div class="progress">
                  <!--  Съедено за день -->
                  <div class="progress-bar bg-success eaten_in_a_day"
                       :style="{ 'width' : (item.current_intake*100/item.daily_intake) +'%' }"
                  >
                    <img src="../../../assets/images/day_for.svg" alt="">
                    <p><span>{{ Math.round(item.current_intake*10) /10 }}</span></p>
                  </div>
                  <!--               рекомендованный-->
                  <div class="progress-bar bg-light daily_rate"
                       :style="{ 'width' :  100 - (item.current_intake*100/item.daily_intake) +'%' }"
                  >
                    <img src="../../../assets/images/day_normal.svg" alt="">
                    <p><span>{{ Math.round(item.daily_intake*10) /10}}</span></p>
                  </div>
                </div>
              </template>
              <template v-if="item.current_intake > item.daily_intake">
                <!--          если сьедано за день больше чем рекоменуемые -->

                <!--          рекомендуемые  = проценты отнасително сьедано за день -->
                <!--          сьедано за день  =  (сьедано за день - рекомендованный) проценты отнасително критического-->

                <!--          <pre>recommend {{ (parseInt(item.daily_intake)*100/parseInt(item.current_intake)) }}</pre>-->
                <!--          <pre>eaten {{ 100 - (parseInt(item.daily_intake)*100/parseInt(item.current_intake)) }}</pre>-->
                <div class="progress">
                  <!-- рекомендованный -->
                  <div class="progress-bar bg-success daily_rate"
                       :style="{ 'width' : (item.daily_intake*100/item.current_intake) +'%' }"
                  >
                    <img src="../../../assets/images/day_normal.svg" alt="">
                    <p><span>{{ Math.round(item.daily_intake*10) /10 }}</span></p>
                  </div>
                  <!--  Съедено за день -->
                  <div class="progress-bar bg-warning eaten_in_a_day"
                       :style="{ 'width' :   100 - (item.daily_intake*100/item.current_intake) +'%' }"
                  >
                    <img src="../../../assets/images/day_for.svg" alt="">
                    <p><span>{{ Math.round(item.current_intake*10) /10 }}</span></p>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </template>
      </template>
      <Preloader v-else></Preloader>
    </template>

  </div>
</template>
<script>
import Progress from "@/api/Progress";
import Preloader from '../../static/Preloader'
export default {
  components: {
    Preloader
  },
  data(){
    return {
      data: null,
      errors: null,
      vitamins: true,
      minerals: false,
      macronutrients: false
    }
  },
  mounted() {
    this.getMolecularAtlasOfHealth()
  },
  methods: {
    getVitamins() {
      this.vitamins = true
      this.minerals = false
      this.macronutrients = false
    },
    getMinerals() {
      this.minerals = true
      this.macronutrients = false
      this.vitamins = false
    },
    getMacronutrients() {
      this.macronutrients = true
      this.minerals = false
      this.vitamins =false
    },
    getMolecularAtlasOfHealth() {
      Progress.getMolecularAtlas(data => this.data = data, errors => this.errors = errors)
    }
  }
}
</script>