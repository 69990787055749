import {HTTP} from '@/http'

class Profile {
    static me(then, catcher, params = null) {
        return HTTP.get('/user-profile', { params })
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors))
    }
    static family(then, catcher, params = null) {
        return HTTP.get('/siblings/family', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static searchFamilyMember(then, catcher, params = null) {
        return HTTP.get('/siblings/search', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static sendRequestToAddFamilyMember(then, catcher, params = null) {
        return HTTP.post('/siblings/requests', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static requestsToMe(then, catcher, params = null) {
        return HTTP.get('/siblings/requests', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static approveRequestToMe(then, catcher, params = null) {
        return HTTP.post('/siblings/requests/approve', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static uploadAvatar(then, catcher, params = null) {
        return HTTP.put('/user-photo', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static editRestricts(then, catcher, params = null) {
        return HTTP.put('user-profile/update-restriction', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static editPurpose(then, catcher, params = null) {
        return HTTP.put('user-profile/update-purpose', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static editParameters(then, catcher, params = null) {
        return HTTP.put('user-profile/update-info', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static editInfo(then, catcher, params = null) {
        return HTTP.put('user-profile/update-fio', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static editPreferences(then, catcher, params = null) {
        return HTTP.put('user-profile/update-preference', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static changePassword(then, catcher, params = null) {
        return HTTP.put('/user-password', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
}
export default Profile