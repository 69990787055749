<template>
  <div class="goal-step abs-full-block forgot-pass">
    <div class="sign-in__flex">
      <p class="w-100 forgot-pass__title">
        <img src="../../../assets/images/nomadiet-logo.png" alt="" class="mr-4 forgot-pass__logo" >
        NomaDiet
      </p>
      <form class="w-100" @submit.prevent="resetPassword">

        <p class="goal-step__title">Забыли пароль?</p>

        <label class="base-label">Email</label>
        <input v-model="email" class="base-input" type="email" id="email" placeholder="E-mail">

        <p class="forgot-pass__text">На этот имейл будет отправлена ссылка для восстановления пароля</p>


        <div class="w-100">
          <button  type="submit" class="base-btn base-btn--gradient">Отправить</button>
        </div>





      </form>

      <div class="w-100">
        <button  @click="goToRegistration" class="base-btn base-btn--violet">Регистрация</button>
      </div>

    </div>
  </div>
</template>
<script>
import Auth from "@/api/Auth";
export default {
  data() {
    return {
      email: null,
      data: null,
      errors: null
    }
  },
  watch: {
    'errors'(){
      if (this.errors) {
        this.$store.dispatch('SHOW_ALERT', [this.errors.message])
      }
    }
  },
  methods: {
    resetPassword(){
      let params = {
        email: this.email
      }
      Auth.resetPassword(data => this.data = data, errors => this.errors = errors, params)
    },
    goToRegistration() {
      this.$router.push({ name: 'registration-step-1' })
    }
  }
}
</script>