<template>
  <div class="container-ul shopping">
    <div class="shopping__header shadow">
      <div class="base-flex py-2">
        <p class="shopping__title mb-0">Покупки на неделю</p>
        <router-link :to="{name: 'basket'}" class="text-bold text-dark">Моя Корзина</router-link>
      </div>
      <div class="shopping__top">
        <button @click="byDish" :class="[ dish ? 'active' : null]">Блюда</button>
        <button @click="byIngredients"  :class="[ ingredients ? 'active' : null]">Ингредиенты</button>
      </div>
    </div>
    <div class="shopping__body">

      <template v-if="dish">
        <template v-if="list_by_dish">
          <ListByDish :data="list_by_dish"></ListByDish>
        </template>
        <Preloader v-else></Preloader>
      </template>

     <template v-if="ingredients">
       <template v-if="list_by_ingredients">
         <ListByIngredients :data="list_by_ingredients"></ListByIngredients>
       </template>
       <Preloader v-else></Preloader>
     </template>

    </div>
  </div>
</template>
<script>
import Shop from '@/api/Shop'
import Preloader from '../../static/Preloader'
import ListByIngredients from './listByIngredients'
import ListByDish from './listByDish'
export default {
  components: {
    Preloader,
    ListByIngredients,
    ListByDish
  },
  data () {
    return {
      list_by_ingredients: null,
      list_by_dish: null,
      errors: null,
      ingredients: false,
      dish: true
    }
  },
  mounted() {
    this.byDish()
  },
  methods: {
    byDish(){
      this.dish = true
      this.ingredients = false
      Shop.listByDishes(data => this.list_by_dish = data, errors => this.errors = errors)
    },
    byIngredients() {
      this.dish = false
      this.ingredients = true
      Shop.listByIngredients(data => this.list_by_ingredients = data, errors => this.errors = errors)
    },

  }
}
</script>