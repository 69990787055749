<template>
  <div class="container-ul pt-3 profile" v-if="user">
    <p class="profile__title">Профиль</p>
    <div class="profile__upload">
      <img :src="profile_image" alt="" v-if="profile_image" class="profile__upload__image">
      <div class="profile__upload__change">
        <input
            type="file"
            @change="onFileChange"
            style="display: none"
            ref="fileInput"
            accept="image/*"
        >
        <img
            src="../../../assets/images/uploadbtn.svg"
            @click="$refs.fileInput.click()"
        >
      </div>
    </div>

    <pre class="alert alert-success mt-3"  v-if="data">
      {{data}}
    </pre>
    <pre v-if="errors" class="alert alert-danger mt-3" >
      {{errors.message}}
    </pre>


    <div class="profile__block">
      <p class="profile__block__title">{{ user.purpose }}</p>
      <div class="base-flex">
        <p class="profile__block__info">
          <span class="mr-3">Вес: {{ user.weight }} кг</span>
          <span>Возраст: {{ user.age }}</span>
        </p>
        <img src="../../../assets/images/select-1.svg"  >

      </div>
      <div class="text-right">
        <router-link
            class="btn btn-light"
            :to="{name: 'edit-goal'}"
        ><img src="../../../assets/images/edit.svg" alt="" class="profile__block__edit">Изменить план</router-link>
      </div>
    </div>

    <div class="profile__settings">
      <p class="profile__settings__title">Настройки</p>
      <ul class="profile__settings__list">
        <li>
          <router-link :to="{name: 'edit-profile'}" class="profile__settings__list__link">
            <div class="base-flex">
              <div>
                <img class="icon" src="../../../assets/images/profile_icon.svg" alt="">
                Редактировать профиль
              </div>
              <img src="../../../assets/images/arrow.svg" alt="">
            </div>
          </router-link>
        </li>
<!--        <li>-->
<!--          <router-link :to="{name: 'my-family'}" class="profile__settings__list__link">-->
<!--            <div class="base-flex">-->
<!--              <div>-->
<!--                <img class="icon" src="../../../assets/images/profil_icon_2.svg" alt="">-->
<!--                Моя семья-->
<!--              </div>-->
<!--              <img src="../../../assets/images/arrow.svg" alt="">-->
<!--            </div>-->
<!--          </router-link>-->
<!--        </li>-->
        <li>
          <router-link :to="{name: 'edit-restricts'}" class="profile__settings__list__link">
            <div class="base-flex">
              <div>
                <img class="icon" src="../../../assets/images/profil_icon_2.svg" alt="">
                Ограничения
              </div>
              <img src="../../../assets/images/arrow.svg" alt="">
            </div>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'edit-preferences'}" class="profile__settings__list__link">
            <div class="base-flex">
              <div>
                <img class="icon" src="../../../assets/images/profile_icon.svg" alt="">
                Предпочтения
              </div>
              <img src="../../../assets/images/arrow.svg" alt="">
            </div>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'edit-parameters'}" class="profile__settings__list__link">
            <div class="base-flex">
              <div>
                <img class="icon" src="../../../assets/images/profile_icon.svg" alt="">
                Параметры
              </div>
              <img src="../../../assets/images/arrow.svg" alt="">
            </div>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'change-password'}" class="profile__settings__list__link">
            <div class="base-flex">
              <div>
                <img class="icon" src="../../../assets/images/profile_icon.svg" alt="">
                Пароль
              </div>
              <img src="../../../assets/images/arrow.svg" alt="">
            </div>
          </router-link>
        </li>
        <li class="text-right">
          <div class="btn btn-primary" @click="logout">Logout</div>
        </li>
      </ul>
    </div>



  </div>
</template>

<script>
import Profile from "@/api/Profile";
import ProfileDefault from '../../../assets/images/default-user-image.png'
export default {
  data() {
    return {
      profile_image: ProfileDefault,
      user: null,
      errors: null,
      data: null,
      avatar: null,
    }
  },
  mounted() {
    this.getProfile()
  },
  watch: {
    'user'(){
      if (this.user.image !== null ) {
        this.profile_image = this.user.image
      }
    }
  },
  methods: {
    logout(){
      this.$store.dispatch('SET_USER')
      window.location.reload()
    },
    getProfile() {
      Profile.me(data => this.user = data, errors => this.errors = errors)
    },
    onFileChange(e) {
      const file = e.target.files[0];
      // this.profile_image = URL.createObjectURL(file);
      this.createImage(file);
    },
    // createImage(file) {
    //
    //
    //   const fd = new FormData()
    //   fd.append('profile_photo', file)
    //
    //   Profile.uploadAvatar(data => this.data = data, errors => this.errors = errors, fd )
    // },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        vm.avatar = e.target.result;
        Profile.uploadAvatar(data => this.data = data, errors => this.errors = errors, {
          profile_photo: vm.avatar
        }).then(() => {
          this.profile_image = vm.avatar
        })
      };
      reader.readAsDataURL(file);
    },
  }
}
</script>




<!--<template>-->
<!--  <div class="hello">-->
<!--    <h1 class="display-4 mb-5 text-muted">MY LOVE MAX</h1>-->
<!--    <input type="file" @change="onFileSelected" >-->
<!--    <button class="btn btn-outline-success" @click="onUpload">Upload</button>-->
<!--  </div>-->
<!--</template>-->
<!--<script> -->
<!--import Profile from "@/api/Profile"; -->
<!--export default {-->
<!--  data() {-->
<!--    return {-->
<!--      selectedFile: null,-->
<!--      data: null-->
<!--    }-->
<!--  },-->
<!--  methods: {-->
<!--    onFileSelected(event) {-->
<!--      this.selectedFile = event.target.files[0]-->
<!--    },-->
<!--    onUpload() { -->
<!--      const fd = new FormData()-->
<!--      fd.append('profile_photo', this.selectedFile, this.selectedFile.name) -->
<!--      Profile.uploadAvatar(data => this.data = data, errors => this.errors = errors, fd)-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</script>-->