import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap/dist/js/bootstrap.min'
import 'popper.js'
import 'jquery/src/jquery'
import './assets/app.scss'
import 'animate.css'
import 'underscore'
import 'slick-carousel/slick/slick.css'
import 'vue-datepicker'
import moment from 'moment'
moment.locale('ru');
import 'chart.js'
import 'vue-chartjs'
import 'vue-tinymce-editor'
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)
import { store } from './store'
import { HTTP } from "@/http";
import 'es6-promise/auto'
import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import 'axios'
import 'vue-axios'

Vue.config.productionTip = false
Vue.use(VueDatePicker)

if (localStorage.token) {
  HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token
  // store.dispatch('SET_USER_FROM_TOKEN')

}

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')

