<template>
  <div class="w-100 pt-3">
    <div v-for="(dish, dish_index) in data" :key="'dish'+dish_index">
      <label
          class="base-checkbox-full"
          v-for="(item, index) in dish"
          :key="index+'shop-list'"
      >
        <div class="shopping__item">
          <img :src="item.image" alt="">
          <div>
            <p class="shopping__item__title">{{ item.name }}</p>
            <p class="shopping__item__description">{{ item.pivot.weight }} грамм</p>
          </div>
        </div>
        <input type="checkbox" :checked="item.status" value="Беременная и кормящая">
        <span class="checkmark-full"></span>
      </label>
    </div>
  </div>
</template>
<script>
export default{
  props: ['data']
}
</script>