<template>
  <div class="goal-step abs-full-block">

    <div class="sign-in__flex">
      <div class="w-100">
        <p class="text-muted mb-0">1 из 5</p>
        <p class="goal-step__title">Регистрация </p>

        <label class="base-label">Имя </label>
        <input class="base-input" type="text"  placeholder=" " name="name" @change="updateValue">

        <label class="base-label">Фамилия</label>
        <input class="base-input" type="text"  placeholder=" " name="surname" @change="updateValue">     

        <label class="base-label">Пол</label>
        <select class="base-input" name="gender" @change="updateValue">
          <option :value="null" selected style="display: none">Выберите пол</option>
          <option value="1">Мужской</option>
          <option value="2">Женский</option>
        </select>

        <label class="base-label">Вес (в кг)</label>
        <input class="base-input" type="number" name="weight" @change="updateValue" placeholder=" ">

        <label class="base-label">Рост</label>
        <input class="base-input" type="number"  placeholder=" " v-model="user.height" name="height" @change="updateValue"> 

        <label class="base-label">Дата рождения</label>
        <VueDatePicker 
          class="base-input" 
          format="YYYY-MM-DD" 
          :max-date="new Date().toISOString().substr(0, 10)"
          min-date="1940-01-01"
          name="birthdate"
          v-model="user.birthdate"
          />
      
        <RegistrationMenu/>
        <div class="w-100">
            <button  @click="collectData" class="base-btn base-btn--violet">Продолжить</button>
          </div>
      </div>

    </div>
  </div>
</template>
<script>
import RegistrationMenu from '../../partials/RegistrationMenu';

export default {
 data() {
    return {
      user: {
        name: null,
        surname: null,
        gender: null,
        weight: null,
        height:null,
        birthdate:null
      },
      alert: false
    }
  },
  components: {
   RegistrationMenu
  },
  mounted() {
    this.checkAuth()
  },
  methods: {
    checkAuth() {
      if(localStorage.token){
        this.$router.push({ name: 'main' })
      }
    },
    updateValue(e){
      this.user = {
        ...this.user,
        [e.target.name]: e.target.value
      }
    },
    validateForm(){
      const keys = Object.keys(this.user);
      const collectString = {str: ""};
      const labels = {
        name:     'имя',
        surname:  'фамилию',
        gender:   'пол',
        weight:   'вес',
        height:   'рост',
        birthdate:'дату рождения'
      }
      for(let x = 0;x < keys.length;x++){
        if(!this.user[keys[x]]){
          collectString.str += ", "+labels[keys[x]];
        }
      }
      return collectString.str.length !== 0 ?  collectString.str.slice(1,collectString.str.length) : false;
    },
    collectData() {
      if(this.validateForm()){
        this.$store.dispatch('SHOW_ALERT', ['Заполните'+ this.validateForm()+"."]);
        return;
      }
      this.$store.dispatch('COLLECT_USER_DATA', this.user)
        .then(() => {
          this.$router.push({ name: 'registration-step-2' })
        })
    }
  }
}
</script>