<template>
  <div class="goal-step abs-full-block">
    <div class="sign-in__flex">
      <div class="w-100">
        <p class="text-muted mb-0">5 из 5</p>
        <p class="goal-step__title">Введите данные</p>
      </div>
      <div class="w-100">

        <label class="base-label">Email</label>
        <input type="email" class="base-input" placeholder=" " name="email" @change="updateValue">

        <label class="base-label">Телефон</label>
        <div class="margin-top"></div>
        <MazPhoneNumberInput
          default-country-code="KZ"
          v-model="user.phone"
          placeholder=""
          noValidation
          noExample
          @update="mask.results = $event"
        />
        <label class="base-label">Пароль</label>
        <input type="password" class="base-input" placeholder=" " name="password" @change="updateValue">

        <label class="base-label">Повторите пароль</label>
        <input type="password" class="base-input" placeholder=" " name="password_confirmation" @change="updateValue">

 </div>

      <div class="w-100">
        <RegistrationMenu/>
        <button  @click="collectData" class="base-btn base-btn--violet">Регистрация</button>
      </div>

    </div>
  </div>
</template>
<script>
import RegistrationMenu from '../../partials/RegistrationMenu'
import Auth from "@/api/Auth";
import "maz-ui/lib/css/index.css";
import { MazPhoneNumberInput } from 'maz-ui'
export default {
  components: {
    RegistrationMenu,
    MazPhoneNumberInput
  },
  computed: {
    user_info() {
      return this.$store.getters.getUser()
    }
  },
  data() {
    return {
      user: {
        email: null,
        phone: null,
        password: null,
        password_confirmation: null
      },
      mask:{
        results:null
      },
      errors: null
    }
  },
  watch: {
    'errors' () {
      if(this.errors!==null){
        this.$store.dispatch('SHOW_ALERT',this.errors)
      }
    },
  },
  methods: {
     updateValue(e){
      this.user = {
        ...this.user,
        [e.target.name]: e.target.value
      }
      console.log(this.mask.results.formatNational)
    },
     validateForm(){
      const keys = Object.keys(this.user);
      const collectString = {str: ""};
      const labels = {
        email: 'почту',
        phone:  'номер телефона',
        password:   'пароль',
        password_confirmation:   'подтверждение пароля'
      }
      for(let x = 0;x < keys.length;x++){
        if(!this.user[keys[x]]){
          collectString.str += ", "+labels[keys[x]];
        }
      }
      if(this.user.password!==this.user.password_confirmation){
        return 'правильно! Пароли не совпадают'
      }
      return collectString.str.length !== 0 ?  collectString.str.slice(1,collectString.str.length) : false;
    },
    collectData() {
       if(this.validateForm()){
        this.$store.dispatch('SHOW_ALERT', ['Заполните'+ this.validateForm()+"."]);
        return;
      }
      this.$store.dispatch('COLLECT_USER_DATA', this.user)
          .then(() => {
              this.register()
          })
    },
    register() {
      let params = this.user_info
      Auth.register(data => this.data = data, errors => this.errors = errors, params)
          .then(()=> {
            if (this.data.success === true) {
              this.$store.dispatch('SET_USER', this.data)
              setTimeout(()=> {
                this.$router.push({ name: 'main' })
              })
            }
          })
    }
  }
}
</script>