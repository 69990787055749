<template>
  <div class="goal-step abs-full-block">


    <div class="sign-in__flex" v-if="restrictions">
      <div class="w-100">
        <p class="goal-step__title">Есть ли ограничения?</p>

        <label class="base-label">Выберите тип болезни</label>
        <select name="cars" id="cars" class="base-input" v-model="illness">
          <option :value="null" selected style="display: none">Тип болезни</option>
          <option
              v-for="(item, index) in restrictions"
              :key="'restriction'+index"
              :value="{name: item.name, 'id': item.id}"
          >{{ item.name }}</option>
        </select>
        <div>
          <span class="base-tag" v-for="(item, index) in illness_list" :key="index">
            {{ item.name }}
            <img src="../../../assets/images/close_tag.svg" alt="X" class="base-tag__delete" @click="deleteIllness(item)">
          </span>
        </div>


      </div>

      <div class="w-100">
        <button  @click="updateData" class="base-btn base-btn--violet">Сохранить</button>
        <router-link :to="{name: 'profile'}" class="forgot-password mb-3">Отменить</router-link>
      </div>

    </div>
  </div>
</template>
<script>
import Restrictions from "@/api/Restrictions";
import Profile from "@/api/Profile";
import _ from 'underscore'
export default {
  data() {
    return {
      illness: null,
      illness_list: [],
      restrictions: null,
      errors: null,
      data: null
    }
  },
  watch: {
    'illness'() {
      if (this.illness){
        this.illness_list.push(this.illness)
      }
    }
  },

  mounted() {
    this.getRestrictions()
  },
  methods: {
    getRestrictions() {
      Restrictions.list(data => this.restrictions = data, errors => this.errors = errors)
    },
    updateData() {
      let restriction_id = _.uniq(
          _.map(this.illness_list, (item)=>{
            return item.id
          })
      )
      let params = {
        restriction_id: restriction_id,
        "_method": "put"
      }
      Profile.editRestricts(data => this.data = data, errors => this.errors = errors, params)
      .then(() => {
        this.$store.dispatch('SHOW_ALERT', [this.data.message])
        setTimeout(() => {
          this.$router.push({ name: 'profile' })
        }, 0)
      })

    },
    deleteIllness(item){
      let index = this.illness_list.indexOf(item);
      // console.log(index)
      if (index > -1) {
        this.illness_list.splice(index, 1);
      }
    }
  }
}
</script>