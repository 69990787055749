<template>
  <div class="main">
    <div class="container-ul pt-3">
      <h1 class="base-title pt-4">Рецепты</h1>
      <form class="input-group mb-3 mt-1" @submit.prevent="searchRecipe">
        <input
            type="text"
            class="profile__search__input form-control"
            placeholder="Искать по названию"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            v-model="to_search"
        >
        <div class="input-group-append">
          <button class="profile__search__btn" type="submit"><img src="../../../assets/images/search.svg" alt="Поиск"></button>
        </div>
      </form> 
      <div class="filter-line">
        <button class="filter-line__all" @click="filter = true">Все</button>
        <template v-if="dish_types">
          <label class="base-checkbox-tag" v-for="(item, index) in dish_types" :key="'dish_types'+index">
            <input type="checkbox" :value="item.id" v-model="checked_dish_types">
            <span class="checkmark-tag">{{ item.name }}</span>
          </label>
        </template>
      </div>
      <transition  name="animate-ul" enter-active-class="animate__animated animate__slideInLeft" leave-active-class="animate__animated animate__slideOutLeft">
        <div class="filter" v-if="filter">
          <div class="filter__top">
            <div class="row">
              <div class="col-4"><button class="filter__top__back" @click="filter = false"><img src="../../../assets/images/arrow-filter.svg" alt=""></button></div>
              <div class="col-4"><p class="mb-0">Фильтры</p></div>
              <div class="col-4 text-right"><button class="filter__top__clear" @click="clearFilter">Сбросить</button></div>
            </div>

          </div>
          <div class="base-tab py-3">
            <button :class="[by_category ? 'active' : null, 'base-tab__item']" @click="ByCategory">По категориям</button>
            <button :class="[by_benefits ? 'active' : null, 'base-tab__item']" @click="ByBenefits">По полезности</button>
          </div>
          <div v-if="by_category" class="filter__body">

            <template v-if="dish_categories">
              <div class="filter__item" v-for="(item, index) in dish_categories" :key="index+'dish_category'">
                <label :for="item.id" class="filter-checkbox">
                  <input type="checkbox" :id="item.id" :value="item.id" v-model="checked_categories">
                  <span class="filter-checkmark">
                  <img :src="item.image" :alt="item.name" class="filter__icon">
                </span>
                </label>
                <p class="filter__item__name">{{item.name}}</p>
              </div>
            </template> 

          </div>
          <div v-if="by_benefits" class="filter__body ">
            <template v-if="micronutrients">
              <label class="base-checkbox-tag" v-for="(item, index) in micronutrients" :key="index+'micro'">
                <input type="checkbox" :value="item.id" v-model="checked_micronutrients">
                <span class="checkmark-tag">{{item.name}}</span>
              </label>
            </template>
          </div>
          <div class="filter__footer">
            <button @click="getRecipesFilteredByCategory">Применить</button>
          </div>
        </div>
      </transition>
 
      <template v-if="recipes">
        <BlockLarge v-for="(item,index) in recipes.data"
                    :key="index+'breakfast'" :data="item"></BlockLarge>


        <template>
          <paginate
              :page-count="Math.ceil(counter/params.per_page)"
              :click-handler="onPaginate"
              :prev-text="'<'"
              :next-text="'>'"
              :container-class="'pagination'">
              v-model="params.page"
          </paginate>
        </template>

      </template>
      <Preloader v-else></Preloader>  
    </div>
  </div>
</template>
<script>
import Recipe from "@/api/Recipe";
import moment from "moment";
import BlockLarge from "@/components/partials/BlockLargeForRecipe";
import Preloader from '@/components/static/Preloader'
export default {
  data(){
    return {
      recipes: null,
      errors: null,
      page: 1,
      per_page: 5,
      to_search: null,

      filter: false,
      by_category: true,
      by_benefits: false,

      dish_types: null,
      checked_dish_types: [],

      micronutrients: null,
      checked_micronutrients: [],

      dish_categories: null,
      checked_categories: [],
      params: {
        page: 1,
        per_page: 5,
      },
      counter: null,
      empty_data: null
    }
  },
  components: {
    BlockLarge, Preloader
  },
  watch: {
    'params.page' () {
      this.getRecipes()
    },
    'checked_dish_types'(){
      if (this.checked_dish_types){
        this.getRecipesFiltered()
      }
    }
  },
  mounted() {
    this.getRecipes()
    this.getDishTypes()
    this.getMicronutrients()
    this.getDishCategories()
  },
  filters: {
    moment(date) {
      return moment(date, 'DD.MM.YYYY').format('MMMM DD, YYYY') ;
    }
  },
  methods: {
    clearFilter(){
      this.checked_categories = []
      this.checked_micronutrients = []
    },
    ByBenefits(){
      this.by_benefits = true
      this.by_category = false
    },
    ByCategory(){
      this.by_category = true
      this.by_benefits = false
    },
    searchRecipe() {
      this.params.name = this.to_search
      this.params.page = 1
      this.getRecipes()
    },
    getRecipes(){
      Recipe.list(data=> this.recipes = data, errors => this.errors = errors, this.params)
      .then(() => {
        this.counter = this.recipes.meta.total
      })

    },
    getRecipesFiltered(){
      if (this.checked_dish_types){
        this.recipes = null
        this.params.page = 1
        this.params.dish_type = this.checked_dish_types
      }
      this.getRecipes()
    },
    getRecipesFilteredByCategory(){
      if (this.checked_categories) {
        this.params.page = 1
        this.recipes = null
        this.params.dish_category = this.checked_categories
      }
      this.filter = false
      this.getRecipes()
    },
    getDishTypes(){
      Recipe.dishTypes(data=> this.dish_types = data, errors => this.errors = errors)
    },
    getMicronutrients(){
      Recipe.micronutrients(data=> this.micronutrients = data, errors => this.errors = errors)
    },
    getDishCategories(){
      Recipe.dishCategories(data=> this.dish_categories = data, errors => this.errors = errors)
    },
    onPaginate (page) {
      this.params.page = page
    }
  }
}
</script>