<template>
  <div class="goal-step abs-full-block">
    <div class="sign-in__flex" >
      <div class="w-100">
        <p class="text-muted mb-0">3 из 5</p>
        <p class="goal-step__title">Насколько ты активен?</p>

        <template v-if="activities">
          <label class="goal-step__selection"
                 v-for="(item, index) in activities" :key="'activity' + index"
                 :for="'activity_type_'+item.id"
          >
            <input type="radio" :id="'activity_type_'+item.id" name="activity" :value="item.id" v-model="activity_id">
            <div class="goal-step__selection__block">
              <div>
                <p class="goal-step__selection__title">{{ item.name }}</p>
                <p class="goal-step__selection__body">{{ item.description }}</p>
              </div>
              <img :src="item.image" :alt="item.name">
            </div>
          </label>
        </template>
        <Preloader v-else></Preloader>


        <RegistrationMenu/>

        <div class="w-100">
          <button class="base-btn base-btn--violet" @click="collectData">Продолжить</button>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
import Activities from "@/api/Activities";
import RegistrationMenu from '../../partials/RegistrationMenu'
import Preloader from '../../static/Preloader'
export default {
  components: {
    RegistrationMenu,
    Preloader
  },
  data(){
    return {
      activities: null,
      errors: null,
      activity_id: null
    }
  },

  mounted() {
    this.getActivities()
  },
  methods: {
    getActivities() {
      Activities.list(data => this.activities = data, errors => this.errors = errors)
    },
    collectData() {
      if (this.activity_id !== null  ) {
        let params = {
          activity_id: this.activity_id
        }
        this.$store.dispatch('COLLECT_USER_DATA', params)
            .then(() => {
              this.$router.push({ name: 'registration-step-5' })
            })
      } else {
        this.$store.dispatch('SHOW_ALERT', ['Выберите один из активности'])
      }
    },
  }
}
</script>